import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { ActiveAnotomyReferences, DataItem, ExamState, ExamDetails, ExamStore, DiagnosticView, Bookmark } from "@/types/exam";
import { ChatMessage } from "@/types/chat";
import { EFrameType, EGalleryAction } from "@/enums/base-enum";
import { PatientDetails } from '@/types/examDetails'
import { EExamStatusId } from "@/types/dashboard";
import IndexedDBService from '../utils/indexdb';

const initialState: ExamState = {
  examData: [],
  activeAnotomyReferences: {
    examId: 0,
    anatomyId: 0,
    diagnosticViewId: 0,
    frameId: 0,
    frameType: EFrameType.THUMBNAIL,
    referenceIndexes: {
      anatomyIndex: 0,
      diagnosticViewIndex: 0,
      frameIndex: 0,
    },
    frameReference: {
        currentFrameBase64: '',
        currentFrameIndex: 0,
        thumbnailId: 0
    }
  },
  galleryActions: [],
  thumbnailIds: [],
  patientDetails: {
    patientName: '',
    patientId: '',
    accessionNumber: '',
    lmp: '',
    ga: '',
    edd: ''
  },
  examDetails: {
    examId: 0,
    status: '',
    statusId: EExamStatusId.PRELIMINARY,
  },
  currentImageIndex: 0,
  totalCount: 0,
  isChatOpen: false,
  activeComment: {} as ChatMessage,
};

const storage = {
    getItem: async (key: string) => {
        try {
            const db = await IndexedDBService.getInstance();
            const item = await db.getKey(key);
            return item ? JSON.parse(item) : null;
        } catch (err) {
            return null;
        }
    },
    setItem: async (key: string, value: unknown) => {
        try {
            const db = await IndexedDBService.getInstance();
            await db.storeKey(key, JSON.stringify(value));
        } catch (err) {
        }
    },
    removeItem: async (key: string) => {
        try {
            const db = await IndexedDBService.getInstance();
            await db.removeKey(key);
        } catch (err) {
        }
    }
};

const getthumbnailIdsFromView = (view: DiagnosticView) => {
    return [
        ...view.thumbnails.map(thumbnail => thumbnail.thumbnailId),
        ...view.clipboards.map(clipboard => clipboard.thumbnailId)
    ];
};

const getthumbnailIdsFromAnatomy = (anatomy: DataItem) => {
    return anatomy.diagnosticViewData.flatMap(getthumbnailIdsFromView);
};

export const getAllThumbnailIds = (examData: DataItem[]) => {
    return examData.flatMap(getthumbnailIdsFromAnatomy);
};

export const useExamStore = create<ExamStore>()(
  persist(
    (set, get) => ({
        ...initialState,
        setExamData: (data: DataItem[]) => {
            set((state: ExamState) => ({
                ...state,
                examData: data,
            }));
        },
        setActiveAnotomyReferences: (references: ActiveAnotomyReferences) => {
            set((state: ExamState) => ({
                ...state,
                activeAnotomyReferences: references,
            }));
            get().getCurrentImageIndexandTotalCount();
        },
        getCurrentImageIndexandTotalCount: () => {
            const state = get();
            const { examData } = state;
            const { anatomyIndex, diagnosticViewIndex, frameIndex } = state.activeAnotomyReferences.referenceIndexes;
            let totalImages = 0;
            let currentGlobalIndex = 0;
    
            for (let i = 0; i < examData.length; i++) {
                const anatomy = examData[i];
                if (!anatomy) continue;
    
                for (let j = 0; j < anatomy.diagnosticViewData.length; j++) {
                    const view = anatomy.diagnosticViewData[j];
                    if (!view) continue;
    
                    const viewTotal = (view.thumbnails?.length || 0) + (view.clipboards?.length || 0);
                    
                    if (i < anatomyIndex || (i === anatomyIndex && j < diagnosticViewIndex)) {
                        currentGlobalIndex += viewTotal;
                    } else if (i === anatomyIndex && j === diagnosticViewIndex) {
                        currentGlobalIndex += frameIndex + 1;
                    }
    
                    totalImages += viewTotal;
                }
            }
            get().setCurrentImageIndexAndTotalCount(currentGlobalIndex, totalImages);  
        },
        setAnatomyFrameTypeAndFrameIdAndFrameIndex: (frameType: EFrameType, frameId: number, frameIndex: number) => {
            set((state: ExamState) => ({
                ...state,
                activeAnotomyReferences: {
                    ...state.activeAnotomyReferences,
                    frameType,
                    frameId,
                    referenceIndexes: {
                        ...state.activeAnotomyReferences.referenceIndexes,
                        frameIndex
                    },
                },
            }));
        },
        resetExamData: () => {
            set((state: ExamState) => ({
                ...state,
                ...initialState
            }));
        },
        resetAnatomyFrameReference: () => {
            set((state: ExamState) => ({
                ...state,
                activeAnotomyReferences: initialState.activeAnotomyReferences,
            }));
        },
        addGalleryAction: (action: EGalleryAction) => {
            set(state => {
                const currentActions: EGalleryAction[] = state.galleryActions;
                
                if (action === EGalleryAction.TRASH) {
                    return { galleryActions: [EGalleryAction.TRASH] };
                }
                
                if (currentActions.includes(EGalleryAction.TRASH)) {
                    return state;
                }
                
                if (!currentActions.includes(action)) {
                    return { galleryActions: [...currentActions, action] };
                }
                
                return state;
            });
        },
        removeGalleryAction: (action: EGalleryAction) => {
            set(state => ({
                galleryActions: state.galleryActions.filter(a => a !== action)
            }));
        },
        addThumbnailId: (thumbnailId: number) => {
            set(state => ({
                thumbnailIds: [...state.thumbnailIds, thumbnailId ]
            }));
        },
        removeThumbnailId: (thumbnailId: number) => {
            set(state => ({
                thumbnailIds: state.thumbnailIds.filter(ele => (ele !== thumbnailId)
                )
            }));
        },
        resetThumbnailIds: () => {
            set({ thumbnailIds: [] });
        },
        selectAllThumbnailIds: () => {
            set(state => ({
                thumbnailIds: getAllThumbnailIds(state.examData)
            }));
        },
        getFrameBase64: (thumbnailId: number): string | null => {
            const state = get();
            const { examData } = state;

            for (const anatomy of examData) {
                for (const view of anatomy.diagnosticViewData) {
                    const thumbnail = view.thumbnails.find(t => t.thumbnailId === thumbnailId);
                    if (thumbnail?.frameBase64) return thumbnail.frameBase64;
                }
            }
            return null;
        },
        setGalleryActions: (galleryActions: EGalleryAction[]) => set({ galleryActions }),
        setthumbnailIds: (thumbnailIds: number[]) => set({ thumbnailIds }),
        clearSelections: () => {
            set(state => ({
                ...state,
                activeAnotomyReferences: initialState.activeAnotomyReferences,
                galleryActions: []
            }));
            get().resetThumbnailIds();
        },
        setPatientDetails: (patientDetails: PatientDetails) => {
            set((state: ExamState) => ({
                ...state,
                patientDetails: patientDetails
            }));
        },
        setExamDetails: (examDetails: ExamDetails) => {
            set((state: ExamState) => ({
                ...state,
                examDetails: examDetails
            }));
        },
        setCurrentImageIndexAndTotalCount: (currentIndex: number, totalCount: number) => {
            set((state: ExamState) => ({
                ...state,
                currentImageIndex: currentIndex,
                totalCount: totalCount
            }));
        },
        setIsChatOpen: (openState) => {
            set(() => ({ isChatOpen: openState }));
        },
        setActiveComment: (comment: ChatMessage | null) => {
            set((state: ExamState) => ({
                ...state,
                activeComment: comment,
            }));
            get().constructActiveReferenceFromComment();
        },
        constructActiveReferenceFromComment: () => {
            const state = get();
            const { examData, activeComment } = state;
            
            if (!activeComment?.frameId) return null;

            // Find the anatomy and its index
            const anatomyIndex = examData.findIndex(
                anatomy => anatomy.anatomyId === activeComment.anatomyId
            );
            
            if (anatomyIndex === -1) return null;
            
            const anatomy = examData[anatomyIndex];
            
            // Find the diagnostic view and its index
            const diagnosticViewIndex = anatomy.diagnosticViewData.findIndex(
                view => view.diagnosticViewId === activeComment.diagnosticViewId
            );
            if (diagnosticViewIndex === -1) return null;
            const view = anatomy.diagnosticViewData[diagnosticViewIndex];
            
            // Determine frame type and find frame index
            const frames = activeComment.frameType === EFrameType.THUMBNAIL ? view.thumbnails : view.clipboards;
            const frameIndex = frames.findIndex(frame => frame.frameId === activeComment.frameId);

            if (frameIndex === -1) return null;
            
            const frame = frames[frameIndex];
            
            const activeReference: ActiveAnotomyReferences = {
                examId: anatomy.examId,
                anatomyId: anatomy.anatomyId,
                diagnosticViewId: view.diagnosticViewId,
                frameId: activeComment.frameId,
                frameType: activeComment.frameType as EFrameType,
                referenceIndexes: {
                    anatomyIndex,
                    diagnosticViewIndex,
                    frameIndex
                },
                frameReference: {
                    currentFrameBase64: frame.frameBase64 ?? '',
                    currentFrameIndex: frameIndex,
                    thumbnailId: frame.thumbnailId
                }
            };
            
            set(state => ({
                ...state,
                activeAnotomyReferences: activeReference
            }));
            return activeReference;
        },
        getBookmarks: () => {
            const state = get();
            const { examData } = state;
            const { anatomyIndex, diagnosticViewIndex, frameIndex } = state.activeAnotomyReferences.referenceIndexes;
            const anatomy = examData[anatomyIndex];
            const view = anatomy.diagnosticViewData[diagnosticViewIndex];
            const frame = view.thumbnails[frameIndex];
            return {
                frameIndex: frame?.frameIndex,
                startFrame: frame?.frameIndices?.[0],
                endFrame: frame?.frameIndices?.[1],
                bookmarkIndices: frame?.bookmarkIndices ?? []
            };        
        },
        setBookmarks: (bookmark: Bookmark)=> {
            const state = get();
            const { examData } = state;
            const { anatomyIndex, diagnosticViewIndex, frameIndex } = state.activeAnotomyReferences.referenceIndexes;
            const bookmarkArray = examData[anatomyIndex].diagnosticViewData[diagnosticViewIndex].thumbnails[frameIndex] ?? [];
            bookmarkArray.bookmarkIndices.push(bookmark)
        },
        getCurrentThumbnail: () => {
            const state = get();
            const { activeAnotomyReferences } = state;
            const { thumbnailId } = activeAnotomyReferences.frameReference;
            return thumbnailId;
        },
        updateClipboardIsReportImage: (anatomyIndex: number, diagnosticViewIndex: number, frameId: number, isReportImage: boolean) => {
            set((state: ExamState) => {
                const newExamData = [...state.examData];
                const clipboards = newExamData[anatomyIndex]?.diagnosticViewData[diagnosticViewIndex]?.clipboards;
                const clipboardIndex = clipboards?.findIndex(clip => clip.frameId === frameId);
                
                if (clipboardIndex !== undefined && clipboardIndex !== -1 && clipboards) {
                    clipboards[clipboardIndex] = {
                        ...clipboards[clipboardIndex],
                        isReportImage
                    };
                }
                
                return {
                    ...state,
                    examData: newExamData
                };
            });
        }
    }),
    {
        name: 'exam-storage',
        storage
    }
  )
);