import axios, { AxiosError, AxiosInstance, HttpStatusCode } from 'axios';
import { toast } from "@/hooks/use-toast";
import { CustomAxiosResponse } from '@/types';
import IndexedDBService from '../utils/indexdb';
/**
 * Handles unauthorized access by clearing storage and redirecting to sign-in.
 * This is called when receiving 401/403 status codes from the API.
 */
async function handleUnauthorized() {
  if (typeof window !== "undefined") {
    localStorage.clear();
    const db = await IndexedDBService.getInstance();
    db.clearStore()
    window.location.href = "/sign-in";
  }
}

/**
 * Checks if the client is currently offline.
 * @returns {boolean} True if offline, false if online
 */
const isOffline = (): boolean => {
  return typeof navigator !== 'undefined' && !navigator.onLine;
};

/**
 * Axios instance with predefined configuration.
 * Includes:
 * - Base URL from environment
 * - Default headers
 * - Request/Response interceptors
 * - Error handling
 * - Toast notifications
 */
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Skip-Toast': 'true',
  },
});

/**
 * Request Interceptor
 * Handles:
 * - Offline detection
 * - Token injection
 * - Security headers
 */
axiosInstance.interceptors.request.use(
  (config) => {
    // Check offline status before making request
    if (isOffline()) {
      toast({
        title: "Offline",
        description: "You are offline. Please check your internet connection and try again.",
        variant: "destructive",
      });
      return Promise.reject(new Error("No Internet Connection"));
    }

    // Add auth token if available and not explicitly skipped
    const skipAuth = config.headers?.['X-Skip-Auth'] === 'true';
    if (!skipAuth) {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    
    // Security headers
    config.headers['X-Frame-Options'] = 'DENY';
    return config;
  },
  (error) => Promise.reject(new Error(error))
);

/**
 * Response Interceptor
 * Handles:
 * - Success messages
 * - Error handling with specific HTTP status codes
 * - Toast notifications
 * - Network errors
 */
axiosInstance.interceptors.response.use(
  (response) => {
    const skipToast = response.config?.headers?.["X-Skip-Toast"];

    const customResponse: CustomAxiosResponse = {
      ...response,
      data: response?.data?.data ?? {},
      success: response.data?.success ?? true,
      message: response.data?.message ?? "",
    };

    if (!skipToast && customResponse.message) {
      toast({
        title: "Success",
        description: customResponse.message,
        variant: "default",
      });
    }

    return customResponse;
  },
 async (error: AxiosError) => {
    const skipToast = error.config?.headers?.["X-Skip-Toast"];

    if (error.response) {
      switch (error.response.status) {
        case HttpStatusCode.GatewayTimeout:
          toast({
            title: "Offline",
            description:
              "You are offline. Please check your internet connection and try again.",
            variant: "destructive",
          });
          return Promise.reject(new Error("Connection Refused"));

        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.Forbidden:
          toast({
            title: "Unauthorized",
            description:
              "You are not authorized to access this resource.",
            variant: "destructive",
          });
         await handleUnauthorized();
          return Promise.reject(
            new Error(
              error.response.status === HttpStatusCode.Unauthorized
                ? "Unauthorized"
                : "Forbidden"
            )
          );

        case HttpStatusCode.BadRequest:
          if (!skipToast) {
            toast({
              title: "Bad Request",
              description:
                error.message || "The request was invalid or cannot be served.",
              variant: "destructive",
            });
          }
          break;

        default:
          toast({
            title: "Error",
            description: error.message || "An unexpected error occurred",
            variant: "destructive",
          });
      }
    } else if (!skipToast && error.request) {
      toast({
        title: "Network Error",
        description: "Please check your internet connection",
        variant: "destructive",
      });
    }
    return Promise.reject(error instanceof Error ? error : new Error("An unknown error occurred"));
  }
);

// Global offline event listener
if (typeof window !== 'undefined') {
  window.addEventListener('offline', () => {
    toast({
      title: "Offline",
      description: "You are offline. Please check your internet connection.",
      variant: "destructive",
    });
  });
}

export default axiosInstance; 