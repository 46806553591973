import { openDB, IDBPDatabase } from 'idb';

class IndexedDBService {
    private static instance: IndexedDBService;
    private readonly dbName = 'KeyStorage_new';
    private readonly storeName = 'omea';
    private db: IDBPDatabase | null = null;
    private isInitialized = false;

    private constructor() {}

    static async getInstance(): Promise<IndexedDBService> {
        if (!IndexedDBService.instance) {
            IndexedDBService.instance = new IndexedDBService();
            await IndexedDBService.instance.initDB();
        }
        return IndexedDBService.instance;
    }

    private async initDB(): Promise<void> {
        if (!this.isInitialized) {
            this.db = await openDB(this.dbName, 1, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains('omea')) {
                        db.createObjectStore('omea');
                    }
                },
            });
            this.isInitialized = true;
            console.log("IndexedDB Initialized");
        }
    }

    private async ensureDB(): Promise<void> {
        if (!this.db) {
            await this.initDB();
        }
    }

    async storeKey(keyName: string, key: string): Promise<void> {
        await this.ensureDB();
        await this.db!.put(this.storeName, key, keyName);
    }

    async removeKey(keyName: string): Promise<void> {
        await this.ensureDB();
        await this.db!.delete(this.storeName, keyName);
    }

    async clearStore(): Promise<void> {
        await this.ensureDB();
        await this.db!.clear(this.storeName);
    }

    async getKey(keyName: string): Promise<string | null> {
        await this.ensureDB();
        return await this.db!.get(this.storeName, keyName) ?? null;
    }
}

export default IndexedDBService;