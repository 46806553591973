export enum API_LIST {
    SIGNIN = 'v1/registration/signin',
    SIGNOUT = 'v1/registration/signout',
    LOGIN = '/auth/login',
    LOGOUT = '/auth/logout',
    REGISTER = '/auth/register',
    GET_PATIENT_DETAILS_BY_EXAM_ID = 'v1/exams/getPatientDetailsByExamId',
    GET_DYNAMIC_FORM = 'v1/DynamicForm/getDynamicForms',
    GET_REPORT_TEMPLATE_HEADERS = 'v1/DynamicForm/getReportTemplateHeaders',
    GET_EXAM_STATUS_AND_COLLABORATION_DETAILS = 'v1/exams/getExamStatusAndCollaborationDetails',
    GET_NOTIFICATIONS = 'v1/notifications/getAllNotificationsByUserId',
    CLEAR_NOTIFICATIONS = 'v1/notifications/clearNotifications',
    UPDATE_NOTIFICATION = 'v1/notifications/updateNotification',
    SEND_PUSH_NOTIFICATION = 'v1/notifications/sendNotification',
    UPDATE_NOTIFICATION_TOKEN = 'v1/notifications/storeNotificationToken',
    GET_EXAM_USER_BY_ID = 'v1/exams/getExamsByUserId',
    GET_USER_PROFILE = 'v1/registration/get-profile',
    UPDATE_USER_PROFILE = 'v1/registration/update-profile',
    GET_DESIGNATION = 'v1/registration/get-designation',
    GET_PREFIXES = 'v1/registration/get-prefixes',
    GET_COUNTRY_CODES = 'v1/registration/get-country-codes',
    GET_PREFERENCE = 'v1/frames/getUserPreferences',
    SET_PREFERENCE = 'v1/frames/updateUserPreferences',
    GALLERY_ACTIONS = 'v1/frames/processActions',
    GET_EXAM_DETAILS = 'v1/exams/getOptimalFrameData',
    GET_DATING_ROWS = 'v1/CustomSection/getDating',
    GET_FETAL_BIOMETRY_ROW = '/v1/CustomSection/getBiometryData',
    GET_FETAL_BIOMETRY_METHOD = '/v1/CustomSection/getMethod',
    GET_FETAL_BIOMETRY_FORMULA = '/v1/CustomSection/getFormula',
    SAVE_REPORT = "/v1/DynamicForm/saveReport",
    UPDATE_REPORT_TEMPLATE = 'v1/exams/updateExamReportTemplate',
    GET_REPORT_TEMPLATES = 'v1/masters/getReportTemplate',
    GET_MESSAGES = 'v1/exams-chat/getMessages',
    SEND_MESSAGE = 'v1/exams-chat/sendMessage',
    GET_PATIENT_NOTES = 'v1/exams-chat/getPatientNotes',
    SAVE_PATIENT_NOTES = 'v1/exams-chat/savePatientNotes',
    TOGGLE_COMMENT_STATUS = 'v1/exams-chat/toggleCommentStatus',
    CHECK_APPROVAL_ACCESS = 'v1/approval/checkApprovalAccess',
    UPDATE_EXAM_APPROVAL_STATUS = 'v1/exams/updateExamApprovalStatus',
    ADD_BOOKMARK_DATA = 'v1/exams/addBookmarkData',
    SAVE_MEASUREMENT = 'v1/frames/addMeasurements',
    GET_ANNOTATION_TEXTS = 'v1/masters/getAnnotationTexts',
    GET_MEASUREMENT_TAGS = 'v1/masters/getMeasurementTags',
    GET_TOOLS = 'v1/masters/getTools',
    SAVE_CLIPBOARD_IMAGE = 'v1/exams/saveAndReplaceAnnotations',
    UPDATE_IS_REPORT_IMAGE = 'v1/exams/updateIsReportImage'
}
