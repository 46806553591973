import { Patient } from "./Patient";
import { Row, Table as TableType } from "@tanstack/react-table";
// Enums
export enum Status {
  Scheduled = "Scheduled",
  InReview = "In Review",
  Preliminary = "Preliminary",
  Revise = "Revise",
  Finalized = "Finalized",
}

export enum EExamStatusId {
  SCHEDULED = 1,
  PRELIMINARY = 2,
  IN_REVIEW = 3,
  REVISE = 4,
  FINALIZED = 6,
  ONGOING = 7,
  PAUSED = 8,
  COMPLETED = 9,
}

export interface UpdateTemplateResponse {
  message: string;
  success: boolean;
}

export interface UpdateTemplateRequest {
  examId: number;
  reportTemplateId: number;
}

export interface Task {
  count: number;
  label: string;
  color: string;
  fontColor: string;
}

export interface User {
  name: string;
  email: string;
  role: string;
  createdAt: string;
  updatedAt: string;
}

export interface ExamData {
  Name: string;
  PatientID: number;
  AccessionNo: string;
  readingphysicianid: number;
  ReadingPhysician: string;
  ExamDateTime: string;
  ExamType: string;
  Status: string;
}

export interface Filters {
  date: string[];
  examType: string[];
  readingPhyId: string[];
}

// Add this type for status counts
export interface StatusCounts {
  [key: string]: number | undefined;
  Finalized?: number;
  "In Review"?: number;
  Preliminary?: number;
  Revise?: number;
  Scheduled?: number;
}

export interface ColumnConfig {
  selectedTemplates: Record<string, string>;
  getStatusClasses: (status: string) => string;
  isPatientNameMenuOpen: boolean;
  isScheduledDateTimeMenuOpen: boolean;
  setSelectedTemplates: (fn: (prev: Record<string, string>) => Record<string, string>) => void;
  setIsPatientNameMenuOpen: (value: boolean) => void;
  setIsScheduledDateTimeMenuOpen: (value: boolean) => void;
  table: any;
  handleActionClick: (params: { id: number; status?: Status; statusId?: EExamStatusId }) => Promise<void> | void;
}


export interface FilterButtonProps {
  patients: Patient[];
  allPatients: Patient[];
  onFilterChange: (filters: Filters) => void;
  selectedStatus: string;
}


export interface FilterItem {
  label: string;
  id?: number;
  value?: number | string;
}
export interface FilterOptions {
  date: string[];
  examType: string[];
  readingPhyId: string[];
}

export interface SelectedFilters {
  date: string[];
  examType: FilterItem[];
  readingPhysician: FilterItem[];
}

export interface ReportTemplateCellProps {
  id: number;
  reportTemplate: string | null;
  selectedTemplates: { [key: number]: string };
  onTemplateChange: (id: number, templateName: string) => void;
  status: Status;
}

export interface SearchInputProps {
  className?: string;
  searchQuery: string;
  onSearch: (value: string) => void;
}

export interface SortableHeaderProps {
  title: string;
  onSort: (descending: boolean) => void;
  className?: string;
}

export interface StatusCellProps {
  status: string;
  getStatusClasses: (status: string) => string;
}

export interface TaskCardProps {
  label: string;
  count: number;
  color: string;
  fontColor: string;
  isSelected: boolean;
  onClick: (status: string, labelId: string) => void;
}

export interface TaskCardListProps {
  statusCounts: StatusCounts;
}

export interface ActionCellProps {
  action: string;
  status: Status;
  id: number;
  onClick: (params: { id: number; status: Status; statusId: EExamStatusId }) => Promise<void> | void;
  selectedTemplate?: string;
  statusId?: EExamStatusId;
}

export interface ExamListItem {
  id: number;
  name: string;
  patientId: string;
  accessionNo: string;
  scheduledDateTime: string;
  examType: string;
  reportTemplateName: string;
  readingPhysician: string;
  status: string;
  statusId: EExamStatusId;
  examTypeID: number;
  readingPhysicianID: number;
  clipboardImages: string;
  totalImages: string;
}

export interface PatientTableProps {
  table: TableType<Patient>;
  isLoading: boolean;
  hasError?: boolean;
  onBottomReached: () => void;
  hasNextPage: boolean;
  totalRecords: number;
  isFetching: boolean;
  handleActionClick: (params: { id: number; status?: Status; statusId?: EExamStatusId }) => Promise<void> | void;
  onTemplateChange?: (id: number, templateName: string) => void;
  selectedTemplates: { [key: number]: string };
}

export interface ReportTemplate {
  id: number;
  name: string;
}

export interface TableRowWithTooltipProps {
  row: Row<Patient>;
  isSelected: boolean;
  onSelect: () => void;
  onBlur: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onMouseMove: (e: React.MouseEvent) => void;
  handleActionClick: (params: { id: number; status: Status; statusId: EExamStatusId }) => Promise<void> | void;
  onTemplateChange: (id: number, templateName: string) => void;
  selectedTemplates: { [key: number]: string };
  templates: ReportTemplate[];
}