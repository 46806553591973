export enum EFrameType {
    THUMBNAIL = 1,
    CLIPBOARD = 2,
};

export enum EAnatomyStatus {
    OPTIMAL = "optimal",
    INPROGRESS = "inprogress",
};

export enum ELayout {
    VERTICAL = "vertical",
    HORIZONTAL = "horizontal",
};

export enum EGalleryAction {
  ARCHIVE = 1,
  DOWNLOAD = 2,
  PRINT = 3,
  EMAIL = 4,
  TRASH = 5
};

export enum EApprovalModule {
    EXAM = 1,
}

export enum EMasterRoles {
    SONOGRAPHER = 1,
    READING_PHYSICIAN = 2,
    MFM = 3,
}

export enum EApprovalStatus {
    APPROVED = 1,
    REJECTED = 2,
    PENDING = 3,
}

export enum ETotalFrames {
    FRAMES = 30,
}

export enum TopNavigationIcon {
    BOOKMARKS = "bookmarks",
}

export enum EActiveReportTab {
    GENERAL = "General",
    FETAL_EVALUATION = "Fetal evaluation"
}

export enum ESIDE_OPTIONS {
    TOP = "top",
    RIGHT = "right",
    BOTTOM = "bottom",
    LEFT = "left"
} 

export enum EALIGN_OPTIONS {
    START = "start",
    CENTER = "center",
    END = "end"
}
