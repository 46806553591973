import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

/**
 * Combines class names and merges them using tailwind-merge.
 * 
 * @param {...ClassValue[]} inputs - A list of class names to be combined.
 * @returns {string} - The merged class names as a single string.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
